import LogoFooter from 'public/img/logos/logo-footer.svg'
import Link from 'next/link'
import analytics from '@/utils/analytics'

export const roadmapLink =
  'https://www.notion.so/annotatetv/6a58e315a20c4e86ac130ce800748b13?v=0e37030ce77f4a5ba340d82f690ad630'

export default function Footer() {
  return (
    <footer className="bg-gray-800" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="space-y-8 xl:col-span-1">
                <LogoFooter className="h-10" />
                <p className="text-gray-400 text-base">
                  &copy; {new Date().getFullYear()} Annotate.tv
                </p>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Features
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/features/transcript">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Transcript
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/features/chat-with-video">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Chat with video
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/features/text-from-video">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Text from video
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/features/share">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Share
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/features/export">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Export
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/demo">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Demo
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/pricing">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Pricing
                      </a>
                    </Link>
                  </li>

                  <li onClick={() => analytics.track('Roadmap clicked')}>
                    <a
                      href={roadmapLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="clickable text-base text-gray-300 hover:text-white"
                    >
                      Roadmap
                    </a>
                  </li>

                  <li onClick={() => analytics.track('Twitter clicked')}>
                    <a
                      href="https://twitter.com/annotate_tv"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="clickable text-base text-gray-300 hover:text-white"
                    >
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Use Cases
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link href="/cases/learning">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Learning
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/cases/teaching">
                      <a className="clickable text-base text-gray-300 hover:text-white">
                        Teaching
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
